

const baseUrl = 'https://windbug-api-dev.sirpi.co.in/'


const isMaintainence = false;
const envUrls = {
    baseUrl, isMaintainence
}



export default envUrls
